* {
  box-sizing: border-box;
}
html,body {
  height: 100%;
  width: 100%;
  background-color: rgb(241, 241, 241);
  font-family:'Work sans';   
}
body {
  margin: 0;
  
}