html,body {
    height: 100%;
    width: 100%;
    //background-color: #EEEEEE;
    background-image:
            linear-gradient(
                            to bottom,
                            rgba(255, 255, 255, 0.6),
                            rgba(255, 204, 203, 0.6)),
            url("assets/backgroundimg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}
.App {
    display: grid;
    height: 100vh;
    grid-template-rows: auto 1fr auto;
    min-height: 100%;

}
.App::after{
    content: "";
    clear: both;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
option {
    outline: none;
}
ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
h1,h2,p {
    padding: 0;
    margin: 0;
}
